<!-- Header to be used only for non logged-in users -->
<script setup lang="ts">
import { FwbNavbar, FwbNavbarLogo, FwbNavbarCollapse, FwbNavbarLink, FwbAvatar, FwbDropdown, FwbListGroup, FwbListGroupItem } from 'flowbite-vue'
import { useRouter, useRoute } from 'vue-router';
import { onBeforeMount } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useProfileStore } from '@/stores/profile';
import { storeToRefs } from 'pinia';
import imageUrl from '@/assets/teachtech_gradient__blue_purp.svg'

const authStore = useAuthStore();
const profileStore = useProfileStore();
const router = useRouter();
const route = useRoute();

const { user } = storeToRefs(authStore);
const {userProfile} = storeToRefs(profileStore);

async function signOut() {
   console.log("login out...");
   await authStore.logout();
   router.push({ path: '/' });
}

// TODO: persist pinia store with local storage, ensure good data flow between stores and in the app
onBeforeMount(async () => {

   // Ensure user data is loaded
   if (!user.value || !user.value.user_metadata) {
      console.error("User data is not available.");
      return;
   }

   // Safely access the account type
   const isTutor = user.value.user_metadata.account_type?.includes('tutor');

   // for debuging
   if (isTutor === undefined) {
      console.error("Account type is undefined.");
      return;
   }

   await profileStore.fetchUserProfile(user.value.id, isTutor);
});

</script>

<template>
   <fwb-navbar sticky class="border-b border-b-gray-300">
      <template #logo>
         <fwb-navbar-logo link="#" alt="Logo" :image-url="imageUrl">
         </fwb-navbar-logo>
      </template>
      <template #default="{ isShowMenu }">
         <fwb-navbar-collapse class="md:mr-20 lg:mr-40" :is-show-menu="isShowMenu">
            <router-link :to="{ name: 'Tutors' }" class="border-b-2 md:border-none">
               <fwb-navbar-link>
                  <div :class="{ 'text-primary-600': route.name === 'Tutors' }" class="flex gap-3 md:gap-2">
                     <svg class="w-4 h-4 md:w-5 md:h-5 self-center dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                           d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                     </svg>
                     <p :class="{ 'font-bold': route.name === 'Tutors' }">
                        Tuteurs
                     </p>
                  </div>
               </fwb-navbar-link>
            </router-link>
            <router-link :to="{ name: 'Sessions' }" class="border-b-2 md:border-none">
               <fwb-navbar-link>
                  <div :class="{ 'text-primary-600 font-bold': route.name === 'Sessions' }" class="flex gap-3 md:gap-2">
                     <svg class=" w-4 h-4 md:w-5 md:h-5  self-center dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                           d="M1 17V2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H3a2 2 0 0 0-2 2Zm0 0a2 2 0 0 0 2 2h12M5 15V1m8 18v-4" />
                     </svg>
                     <p :class="{ 'font-bold': route.name === 'Sessions' }">
                        Mon tutorat
                     </p>
                  </div>
               </fwb-navbar-link>
            </router-link>

            <router-link :to="{ name: 'Messages' }" class="border-b-2 md:border-none">
               <fwb-navbar-link>
                  <div :class="{ 'text-primary-600 font-bold': route.name === 'Messages' }" class="flex gap-3 md:gap-2">
                     <svg class="w-5 h-5 md:w-6 md:h-6  dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                           d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z" />
                     </svg>
                     <p :class="{ 'font-bold': route.name === 'Messages' }">
                        Messages
                     </p>
                  </div>
               </fwb-navbar-link>
            </router-link>

            <!--Show only if cant see image logo-->
            <router-link :to="{ name: 'ProfileSettings' }" class="md:hidden border-b-2 ">
               <fwb-navbar-link>
                  <div :class="{ 'text-primary-600 font-bold ': route.name === 'ProfileSettings' }" class="flex gap-3">

                     <svg class="w-4 h-4 md:w-5 md:h-5 self-center dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                           d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 11 14H9a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 10 19Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                     </svg>
                     <p :class="{ 'font-bold': route.name === 'ProfileSettings' }">
                        Profil
                     </p>
                  </div>
               </fwb-navbar-link>
            </router-link>



            <fwb-navbar-link @click.prevent="signOut" class="md:hidden border-t-4">
               <p class="text-red-400">
                  Déconnexion
               </p>
            </fwb-navbar-link>


         </fwb-navbar-collapse>
      </template>
      <template #right-side>
         <fwb-dropdown placement="left">
            <template #trigger>
               <img v-if="userProfile?.profile_image_url" class="w-12 h-12 cursor-pointer rounded-full object-cover" :src="userProfile.profile_image_url" alt="Medium avatar">
               <div v-else class="relative inline-flex items-center cursor-pointer justify-center w-12 h-12 overflow-hidden bg-gradient-to-r from-primary-200 to-purple-200 rounded-full dark:bg-gray-600">
                     <span class="font-medium text-gray-600 text-xl dark:text-gray-300 uppercase">{{ userProfile?.first_name.charAt(0)}}</span>
               </div>            
            </template>
            <fwb-list-group>
               <div class="px-4 py-3 border-b-2">
                  <span class="block text-sm text-gray-900 dark:text-white">{{ userProfile?.first_name }}</span>
                  <span class="block text-sm  text-gray-500 truncate dark:text-gray-400">{{ user?.email }}</span>
               </div>
               <router-link :to="{ name: 'ProfileSettings' }">
                  <fwb-list-group-item class="hover:bg-gray-200">
                     <template #prefix>
                        <svg class="w-4 h-4 fill-current" fill="currentColor" viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                              clip-rule="evenodd"></path>
                        </svg>
                     </template>
                     <router-link :to="{ name: 'ProfileSettings' }">Profil</router-link>
                  </fwb-list-group-item>
               </router-link>
               <hr class="border-t-4">

               <fwb-list-group-item @click="signOut" class="text-red-600 cursor-pointer hover:bg-gray-200">
                  <p class=" font-normal ">Déconnexion</p>
               </fwb-list-group-item>
            </fwb-list-group>
         </fwb-dropdown>
      </template>


</fwb-navbar>
</template>