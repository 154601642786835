<template>
  <div id="app">
    <LoggedInHeader v-if="user?.role === 'authenticated'" />
    <LoggedOutHeader v-else />

    <!--to separate sticky header from content-->
    <!-- <div class="h-14 invisible" /> -->

    <main class="" >
      <router-view />
    </main>
    
    <Footer v-if="route.name !== 'Messages'" />
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia';
import LoggedInHeader from '@/components/LoggedInHeader.vue'
import Footer from '@/components/Footer.vue'
import LoggedOutHeader from './components/LoggedOutHeader.vue';
import { useRoute } from 'vue-router';

const userStore = useAuthStore();
const { user } = storeToRefs(userStore);
const route = useRoute();

</script>