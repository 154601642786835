Header to be used only for non logged-in users

<script setup lang="ts">
import { ref } from 'vue';
import LoginModal from '@/components/LoginModal.vue';
import { FwbButton, FwbNavbar, FwbNavbarLogo, FwbNavbarCollapse, FwbNavbarLink } from 'flowbite-vue';

const isShowModal = ref(false);

import imageUrl from '@/assets/teachtech_gradient__blue_purp.svg';
</script>

<template>
  <fwb-navbar class="font-medium" sticky>
    <template #logo>
      <router-link :to="{ name: 'Landing' }">
        <fwb-navbar-logo alt="TeachTech logo" :image-url="imageUrl" link="#" />
      </router-link>
    </template>
    <template #default="{ isShowMenu }">
      <fwb-navbar-collapse :is-show-menu="isShowMenu" class="md:hidden">

        <fwb-navbar-link link="#" @click="isShowModal = true" class="border-b-2">
          <div class="flex gap-3">
            <svg class="w-4 h-4 self-center text-gray-800 dark:text-white" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M16 12H4m12 0-4 4m4-4-4-4m3-4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2" />
            </svg>
            <p>Se connecter</p>
          </div>
        </fwb-navbar-link>


        <router-link :to="{ name: 'TutorSignup' }">
          <fwb-navbar-link link="#">
            
            <div class="flex gap-3">
              <svg class="w-4 h-4 self-center text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
    <path fill-rule="evenodd" d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z" clip-rule="evenodd"/>
  </svg>
         <p>Devenir tuteur</p>
          </div>
          </fwb-navbar-link>
        </router-link>
      </fwb-navbar-collapse>
    </template>
    <template #right-side>
      <div>

        <fwb-button class="mr-4" size="md" @click="isShowModal = true" gradient="purple-blue">
          Se connecter
        </fwb-button>


        <fwb-button size="sm" outline gradient="purple-blue">
          <router-link :to="{ name: 'TutorSignup' }">
            Devenir tuteur
          </router-link>
        </fwb-button>


      </div>
    </template>
  </fwb-navbar>

  <transition name="modal">
    <LoginModal v-if="isShowModal" @close="isShowModal = false" />
  </transition>
</template>

<style scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.8s;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}</style>
