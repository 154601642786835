import { ref } from 'vue';

export function useApiWrapper() {
  const status = ref<'idle' | 'loading' | 'error' | 'success'>('idle');
  const result = ref(null);
  const error = ref(null);

  async function execute(promise: Promise<any>) {
    status.value = 'loading';
    error.value = null;
    result.value = null;

    // for requests that can trhow exceptions
    try {
      result.value = await promise;
      status.value = 'success';
    } catch (e) {
      status.value = 'error';
    }

    // for supabase direct calls, since they put errors in the returned value
    if (result.value?.error) {
        error.value = result.value.error;
        status.value = 'error';
    }

    return { status: status.value, result: result.value, error: error.value };
  }

  return { status, result, error, execute };
}