import { defineStore } from 'pinia';
import { supabase } from '@/supabase/init';
import { Database } from '@/types/supabase';

export type StudentProfileRow = Database['public']['Tables']['student_profiles']['Row'];
export type TutorProfileRow = Database['public']['Tables']['tutor_profiles']['Row'];

export const useProfileStore = defineStore('profile', {
  state: () => ({
    userProfile: null as StudentProfileRow | TutorProfileRow | null,
  }),
  actions: {

    // this is used only for students
    // TODO: refactor this to be more generic atm its exclusive for student profiles
    async uploadProfilePic(file: File) {
      // upload to bucket first
      const userId = `${this.userProfile?.id}`;
      const { error } = await supabase.storage.from('profile_pics').upload(userId, file, { upsert: true });
      if (error) throw error;
      
      //if upload to bucket is success change column in the profile 
      const imageUrl = `https://qdtgkmdygvqzbnpegbjh.supabase.co/storage/v1/object/public/profile_pics/${userId}`;
      await this.updateProfile({profile_image_url: imageUrl}, false);
    },

    // TODO: refactor this to be more generic atm its exclusive for tutor profiles
    async updateProfile(updatedFields: any, isTutor: boolean) {
      console.log("updating profile...");

      const userId = `${this.userProfile?.id}`;

      // the tutor profile has a profile_image_file column, not the same process as student profile image upload
      if (isTutor && updatedFields.profile_image_file[0]) {

        const img_file = updatedFields.profile_image_file[0].file;

        // upload to bucket first
        const { error } = await supabase.storage.from('profile_pics').upload(userId, img_file, { upsert: true });
  
        if (error) throw error;
        const imageUrl = `https://qdtgkmdygvqzbnpegbjh.supabase.co/storage/v1/object/public/profile_pics/${userId}`;

        // add profile_image_url to updatedFields
        updatedFields.profile_image_url = imageUrl;
      }

      // remove profile_image_url from updatedFields
      // because it is not a column in the table
      delete updatedFields.profile_image_file;

      const tableName = isTutor ? 'tutor_profiles' : 'student_profiles';

      const {error} = await supabase.from(tableName)
      .update({...updatedFields})
      .eq('id', userId);

      if (error)  {
        console.error(error);
        
        throw error;
      };
    },

    async fetchUserProfile(id: string, isTutor: boolean) {

      const tableName = isTutor ? 'tutor_profiles' : 'student_profiles';

      const { data, error } = await supabase
        .from(tableName)
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      this.userProfile = data;
    },
  },
});