import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './assets/style.css'
import App from './App.vue'
import router from './router'
import { createHead } from '@unhead/vue'

import { plugin, defaultConfig } from '@formkit/vue'
import config from '../formkit.config.ts'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import ElementPlus from 'element-plus';

import VueGtag from "vue-gtag";


async function init() {
    const gaTag = import.meta.env.VITE_GA_TAG;
    const pinia = createPinia();
    const head = createHead();
    const app = createApp(App);
    app.use(pinia);
    app.use(router);
    // add font awesome icons to library
    library.add(faHeart);
    app.component('font-awesome-icon', FontAwesomeIcon);

    // use head for SEO meta tags
    app.use(head);

    app.use(ElementPlus);

    app.use(plugin, defaultConfig(config));

    app.use(VueGtag, {
      appName: 'TeachTech',
      config: { id: gaTag}
    });

    app.mount('#app');
}

init().catch((err) => {
    console.error(err);
});

