import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/stores/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Landing",
    component: () => import("@/views/common/Landing.vue"),
  },

  {
    path: "/tutor-signup",
    name: "TutorSignup",
    //TODO: add routes prefixers for folder (eg: tutorRoutes="@/views/tutor")
    component: () => import("@/views/tutor/TutorSignup.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("@/views/common/Faq.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/common/Contact.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/common/PrivacyPolicy.vue"),
  },
  {
    path: "/terms",
    name: "TermsAndConditions",
    component: () => import("@/views/common/TermsAndConditions.vue"),
  },

  // protected routes below
  {
    path: "/tutors",
    name: "Tutors",
    component: () => import("@/views/common/Tutors.vue"),
  },
  {
    path: "/tutor/:tutorId",
    name: "Tutor",
    component: () => import("@/views/student/TutorBooking.vue"),
  },
  {
    path: "/profile",
    name: "ProfileSettings",
    component: () => {
      // route to the correct profile settings page based on the user's account type
      // const authStore = useAuthStore();
      // const { user } = storeToRefs(authStore);
      // if (user.value?.user_metadata.account_type === "tutor") {
      //   return import("@/views/tutor/ProfileSettings.vue");
      // } else {
        return import("@/views/common/ProfileSettings.vue");
  
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: () => import("@/views/common/Sessions.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/messages/:convoId?",
    name: "Messages",
    component: () => import("@/views/common/Messages.vue"),
    meta: { requiresAuth: true },
  },

  // Catch-all route for 404
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/common/404NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
});

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore();

  // this object will be in local storage if user is or was authenticated
  // need it to know to not init auth if user never logged in 
  const supabaseAuth = localStorage.getItem("sb-qdtgkmdygvqzbnpegbjh-auth-token");
  
  if(authStore.user === null
  && supabaseAuth != undefined) {
    await authStore.initializeAuth();
  }

  if (to.meta.requiresAuth && !authStore.user) {
    next({ name: "Landing" });
    return;
  }

  if ((to.path === "/" || to.path === "/tutor-signup") && authStore.user) {
    next({ name: "Tutors" });
    return;
  }

  next();
});

export default router;
